// initial state
const state = () => ({
    accueil: null,
    id: null,
    firstName: null,
    lastName: null,
    login: null,
    rights: [],
    isAdmin: false,
    notifications: 0,
    lastDateNotification: null,
    showNotification: false,
    residenceDisplayChoice: null,
    displayAccessCard: "card",
    displayKeyCard: "card",
    residenceId: null,
    buildingId: null,
    stairwellId: null,
    appartId: null,
    apartmentsCount: null,
    language: null,
    define: [],
    country: 1,
    closeV1warning: false,
    manage: false,
    type: null,
    ipass: false,
    device: null,
    version: "4.18.2",
})
// getters
const getters = {
    getAccueil: (state) => {
        return state.accueil
    },
    getId: (state) => {
        return state.id
    },
    getFirstName: (state) => {
        return state.firstName
    },
    getLastName: (state) => {
        return state.lastName
    },
    getFullName: (state) => {
        return state.firstName + " " + state.lastName
    },
    getLogin: (state) => {
        return state.login
    },
    isAdmin: (state) => {
        return state.isAdmin
    },
    getNotifications: (state) => {
        return state.notifications
    },
    getLastDateNotification: (state) => {
        return state.lastDateNotification
    },
    getShowNotification: (state) => {
        return state.showNotification
    },
    getDisplayAccessCard: (state) => {
        return state.displayAccessCard
    },
    getDisplayKeyCard: (state) => {
        return state.displayKeyCard
    },
    getResidenceDisplayChoice: (state) => {
        return state.residenceDisplayChoice
    },
    getResidenceId: (state) => {
        return state.residenceId
    },
    getBuildingId: (state) => {
        return state.buildingId
    },
    getStairwellId: (state) => {
        return state.stairwellId
    },
    getAppartId: (state) => {
        return state.appartId
    },
    getApartmentsCount: (state) => {
        return state.apartmentsCount
    },
    getLanguage: (state) => {
        return state.language
    },
    getDefine: (state) => {
        return state.define
    },
    getCountry: (state) => {
        return state.country
    },
    getV1Warning: (state) => {
        return state.closeV1warning
    },
    getResidenceManage: (state) => {
        return state.manage
    },
    getType: (state) => {
        return state.type
    },
    getDevice: (state) => {
        return state.device
    },
    getVersion: (state) => {
        return state.version
    },

    getIpass: (state) => {
        return state.ipass
    },
}

// actions
const actions = {
    setWarning({ commit }, value) {
        localStorage.setItem("closeV1warning", value)
        commit("WARNING", value)
    },
}

const mutations = {
    setAccueil(state, value) {
        state.accueil = value
    },
    setId(state, value) {
        state.id = value
    },
    setFirstName(state, value) {
        state.firstName = value
    },
    setLastName(state, value) {
        state.lastName = value
    },
    setLogin(state, value) {
        state.login = value
    },
    setAdmin(state, value) {
        state.isAdmin = value
    },
    setNotifications(state, value) {
        state.notifications = value
    },
    setLastDateNotification(state, value) {
        state.lastDateNotification = value
    },
    setShowNotification(state, value) {
        state.showNotification = value
    },
    setDisplayAccessCard(state, value) {
        state.displayAccessCard = value
    },
    setDisplayKeyCard(state, value) {
        state.displayKeyCard = value
    },
    setResidenceDisplayChoice(state, value) {
        state.residenceDisplayChoice = value
    },
    setResidenceId(state, value) {
        state.residenceId = value
    },
    setBuildingId(state, value) {
        state.buildingId = value
    },
    setStairwellId(state, value) {
        state.stairwellId = value
    },
    setAppartId(state, value) {
        state.appartId = value
    },
    setApartmentsCount(state, value) {
        state.apartmentsCount = value
    },
    setLanguage(state, value) {
        state.language = value
    },
    setDefine(state, value) {
        state.define = value
    },
    setCountry(state, value) {
        state.country = value
    },
    WARNING(state, value) {
        state.closeV1warning = value
    },
    setResidenceManage(state, value) {
        state.manage = value
    },
    setResidenceType(state, value) {
        state.type = value
    },
    setDevice(state, value) {
        state.device = value
    },
    setState(state, data) {
        state.id = data.id
        state.firstName = data.firstName
        state.lastName = data.lastName
        state.login = data.login
        state.isAdmin = data.isAdmin
        state.notifications = data.notifications
        state.displayAccessCard = data.displayAccessCard
        state.displayKeyCard = data.displayKeyCard
        state.residenceDisplayChoice = data.residenceDisplayChoice
        state.residenceId = data.residenceId
        state.buildingId = data.buildingId
        state.stairwellId = data.stairwellId
        state.appartId = data.appartId
        state.apartmentsCount = data.apartmentsCount
        state.language = data.language
        state.define = data.define
        state.country = data.country
        state.closeV1warning = data.closeV1warning
        state.manage = data.manage
        state.type = data.type
    },

    setIpass(state, value) {
        state.ipass = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
