const state = () => ({
    countries: [],
    industryFields: [],
    languages: [],
    startStepReplaceModule: null,
    canKiprogBeUsed: true,
})

// getters
const getters = {
    getStartStepReplaceModule: (state) => {
        return state.startStepReplaceModule
    },
    getAllCountries: (state) => {
        return state.countries
    },
    getAllFormatedCountries: (state) => {
        const formatedCountries = []
        state.countries.forEach((element) => {
            formatedCountries.push({
                name: element.translate,
                value: element.id,
            })
        })
        return formatedCountries
    },
    getCountryByCode: (state) => (code) => {
        return state.countries.find(
            (element) => element.code.toLowerCase() === code.toLowerCase()
        ).img
    },
    getAllIndustryFields: (state) => {
        return state.industryFields
    },
    getAllFormatedIndustryFields: (state) => {
        const FormatedIndustryFields = []
        state.industryFields.forEach((element) => {
            FormatedIndustryFields.push({
                name: element.translate,
                value: element.id,
            })
        })
        return FormatedIndustryFields
    },
    getCanKiprogBeUsed: (state) => {
        return state.canKiprogBeUsed
    },
}

// actions
const actions = {}

// mutations
const mutations = {
    setStartStepReplaceModule(state, value) {
        state.startStepReplaceModule = value
    },
    setCountries(state, value) {
        state.countries = value
    },
    setIndustryFields(state, value) {
        state.industryFields = value
    },
    setLanguages(state, value) {
        state.languages = value
    },
    setCanKiprogBeUsed(state, value) {
        state.canKiprogBeUsed = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
