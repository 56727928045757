<template>
    <div class="alert-modal" ref="modal">
        <basic-button @click="close" color="orange" class="close-button">
            {{ $t("button.close") }}
        </basic-button>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"
export default {
    name: "alert",
    components: { BasicButton },
    props: {
        question: {
            type: String,
            required: true,
        },
        refuse: {
            type: Function,
            required: false,
        },
    },
    mounted() {
        this.deleteTitle()
    },
    methods: {
        close() {
            if (this.refuse) {
                this.refuse()
            }
            this.closeModal()
        },
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.close-button {
    padding: 0 1.5em;
}
</style>
