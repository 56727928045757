/**
 * Apartment
 */
export const apartmentSummaryById = [{}]

/**
 * Account
 */
export const registerFormat = [
    { name: "user", required: true },
    { name: "pwd", required: true },
    { name: "pwd_confirm", required: true },
    { name: "nom", required: true },
    { name: "prenom", required: true },
    { name: "secteur", required: true },
    { name: "ville", required: true },
    { name: "pays_id", required: true },
    { name: "societe", required: false },
    { name: "siret", required: false },
    { name: "email", required: true },
    { name: "cp", required: true },
    { name: "adresse", required: true },
]

export const passwordSecurityFormat = [
    { name: "user", required: false },
    { name: "pwd", required: true },
]

export const resetPasswordFormat = [
    { mode: "mode", required: false },
    { name: "code", required: false },
    { name: "data", required: true },
    { name: "newPwd", required: false },
    { name: "newPwdConfirm", required: false },
]

/**
 * Auth
 */
export const signInFormat = [
    { name: "user", required: true },
    { name: "pwd", required: true },
]

/**
 * Central unit
 */
export const centralUnitsByResidenceFormat = [{ name: "idres", required: true }]

/**
 * Home
 */
export const homeInformationsFormat = [
    { name: "user", required: true },
    { name: "messages", default: "unread" },
]

/**
 * Residence
 */
export const residencesFormat = [
    { name: "limit", default: 20 },
    { name: "centrale", default: 1 },
]

export const residenceSummaryByIdFormat = [
    { name: "limit_acces", default: 8 },
    { name: "limit_appart", default: 100 },
    { name: "limit_cages", default: 350 },
    { name: "limit_keys", default: 100 },
]

export const residenceFormat = [
    { name: "nom", required: true },
    { name: "adr", required: true },
    { name: "cp", required: false },
    { name: "ville", required: false },
    { name: "pays_id", required: false },
    { name: "simple", required: false },
    { name: "liberale", required: false, default: 0 },
    { name: "lat", required: false },
    { name: "lon", required: false },
    { name: "geoarea_code", required: true },
    { name: "agence_id", required: false, default: null },
    { name: "comments", required: false },
    { name: "loc", required: false },
]

export const rgpdFormat = [{ name: "rgpd", required: true }]
export const cageRgpdFormat = [{ name: "list", required: true }]

/**
 * Cage
 */
export const cagesFormat = [
    { name: "idres", required: false },
    { name: "limit", default: 10 },
    { name: "page", required: false, default: 1 },
    { name: "ghost", required: false, default: 0 },
]

/**
 * Fob
 */
export const fobsFormat = [{ name: "limit", default: 20 }]
export const usedAndUnusedFobsFormat = [
    { name: "residence_id", required: true },
    { name: "cage_id", default: 0, required: false },
    { name: "period", default: "", required: false },
    { name: "q", default: "", required: false },
    { name: "page", default: 1, required: false },
    { name: "limit", default: 20, required: false },
]

/**
 * Resident
 */
export const residentsFormat = [{ name: "limit", default: 20 }]

/**
 * Access
 */
export const accessFormat = [
    { name: "q", required: false },
    { name: "hf", required: false },
    { name: "lect", required: false },
    { name: "kibolt", required: false },
    { name: "clav", required: false },
    { name: "for", required: false },
    { name: "vigik", required: false },
    { name: "idres", required: false },
    { name: "idcag", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 8 },
]

/**
 *  Print Central Units
 */
export const CentralUnitsFormat = [
    { name: "idres", required: true },
    { name: "upd", required: false },
    { name: "compt", required: false },
    { name: "type", required: false },
]

/**
 * Outdateds Central
 */
export const outdatedsFormat = [
    { name: "q", required: false },
    { name: "page", required: false },
    { name: "limit", required: false },
    { name: "numprod", required: false },
    { name: "idresidence", required: false },
    { name: "token", required: false },
    { name: "delay", required: false },
]

/**
 * AccessType
 */
export const accessTypeFormat = [
    { name: "q", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 20 },
    { name: "stats", required: false },
    { name: "intratone", required: false },
    { name: "country_id", required: false },
]
/**
 * accessTypeFormatActivities
 */
export const accessTypeActivitiesFormat = [
    { name: "q", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 20 },
    { name: "code", required: false },
    { name: "all", required: false, default: true },
]

/**
 * accessTypeAccessFormat
 */
export const accessTypeAccessFormat = [
    { name: "q", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 20 },
    { name: "access_type_id", required: false },
    { name: "origin", required: false },
]

/**
 * accessTypeProviderFormat
 */
export const accessTypeProviderFormat = [
    { name: "q", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 20 },
    { name: "activity_id ", required: false },
    { name: "all", required: false, default: 0 },
]

/**
 * saveProviderScheduleFormat
 */
export const saveProviderScheduleFormat = [
    { name: "mode", required: true },
    { name: "days", required: true },
]

/**
 *  Version
 */
export const versionError = [
    { name: "vClient", required: true },
    { name: "vCurrent", required: true },
]

/**
 *  Code By Access
 */
export const codeByAccessFormat = [{ name: "idres", required: true }]

/**
 *  Access Type
 */
export const addActivitiesToAccessTypeFormat = [
    { name: "add", required: true },
    { name: "del", required: true },
]

export const createAccessTypeFormat = [
    { name: "name", required: true },
    { name: "description", required: false },
    { name: "access_type_id ", required: false },
]

export const updateAccessTypeFormat = [
    { name: "id", required: true },
    { name: "name", required: false },
    { name: "description", required: false },
    { name: "access_mode_priority ", required: false },
    { name: "to_update", required: false },
]

export const changeAccessModePriorityFormat = [
    { name: "id", required: true },
    { name: "name", required: false },
    { name: "description", required: false },
    { name: "access_mode_priority", required: false },
]

/**
 *  Area
 */
export const areasFormat = [
    { name: "q", required: false },
    { name: "code", required: false },
    { name: "page", required: false, default: 1 },
    { name: "limit", required: false, default: 20 },
]

export const agenceFormat = [{ name: "idres", required: false }]

export const geocodingSearchFormat = [
    { name: "q", required: false },
    { name: "street", required: false },
    { name: "state", required: false },
    { name: "country", required: false },
    { name: "postalcode", required: false },
    { name: "page", required: false },
    { name: "limit", required: false },
]

export const activityBlacklistAndWhitelistFormat = [
    { name: "q", required: false },
    { name: "page", required: false },
    { name: "limit", required: false },
    { name: "blacklist", required: false },
    { name: "whitelist", required: false },
    { name: "reset", required: false },
]

/**
 * MFA Auth Format
 */

export const mfaAuthFormat = [
    { name: "user", required: true },
    { name: "device", required: true },
    { name: "timestamp", required: true },
    { name: "mfa_signature", required: true },
]
/**
 *  Kibolt
 */
export const kiboltKeyFormat = [
    { name: "serial", required: true },
    { name: "couleur", required: false },
    { name: "type", required: false },
]
